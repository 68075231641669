<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="支付中心" left-arrow :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="mian">
      <div class="mian_top">
        <div>
          <div>{{shangjia.name}}</div>
          <div class="sz">互联科技引领 车主新生活</div>
        </div>
        <img class="imgs" src="../../assets/img/logos.png" alt="">
      </div>
      <div class="mian_b">
        <div class="middle">
          <div class="middle_a" :class="acv == 0 ? 'acv' : ''" @click="acv = 0">
            <div>收银台</div>
            <img class="heng" v-if="acv == 0" src="../../assets/img/xiaheng.png" alt="">
          </div>
          <div class="middle_a" :class="acv == 1 ? 'acv' : ''" @click="acv = 1">
            <div>门店收款</div>
            <img class="heng" v-if="acv == 1" src="../../assets/img/xiaheng.png" alt="">
          </div>
        </div>
        <div class="cardiv" v-if="acv == 0">
          <div class="carname">输入车牌，智能支付，免填金额</div>
          <vnp-input v-model="value2"></vnp-input>
          <!-- <div class="car" @click="show = true">
            <div class="carnumber" v-for="(item,idx) in val" :key="idx"></div>
          </div> -->
        </div>

        <div class="bind-phone" v-if="acv == 0">
          <div class="tips" v-if="phoneisok">填写手机号，下次扫码支付免填车牌哦</div>
          <div class="input-phone" v-if="phoneisok">
            <input placeholder="请输入您的手机号码" v-model="phone" type="tel">
            <van-button type="primary" color="linear-gradient(to right, #11b98d, #0fa079)" size="small" @click="setphone">确认</van-button>
          </div>

        </div>
        <!-- <div class="bind-phone" v-if="acv == 0 ">
          <div class="tips">暂无订单信息,请重新输入车牌查询</div>
        </div> -->
        <div class="bind-phone" v-if="acv == 0">
          <div class="carname" v-if="carlist.length > 0">您的爱车有以下待支付信息</div>
          <div class="fang" :class="acvred==is?'acvred':''" v-for="(item,is) in carlist" :key="is" @click="setpay(item,is)">
            <div class="content">
              <div class="title">{{item.carNo}}</div>
              <div class="offer_price">{{item.status>=4?'待支付':'待结算确认'}}</div>
              <div class="offer_price">￥{{item.amountReceivable}}</div>
            </div>
            <div class="red-btn">{{item.status>=4?'立即支付':'预付款'}}</div>
          </div>
          <div v-if="yfkisok">
            <div class="carname">请(输入/修改)支付金额</div>
            <div class="recharge_price">
              <div class="unit">￥</div>
              <input class="jiner" v-model="amount" type="number">
            </div>
            <van-button type="primary" class="paydiv" @click="pay" color="linear-gradient(to right, #11b98d, #0fa079)">确认支付
            </van-button>
          </div>

        </div>
        <div class="cardiv" v-if="acv == 1">
          <div class="carname">请选择您的服务类型</div>
          <div class="list">
            <div v-for="(item, idx) in list" :key="idx" :class="acvs == item ? 'acvs' : ''" @click="acvs = item">{{ item
            }}</div>
          </div>
          <div class="carname">请输入金额</div>
          <div class="recharge_price">
            <div class="unit">￥</div>
            <input v-model="amount2" class="jiner" type="number">
          </div>
          <div v-if="acvs == '其他'">
            <div class="carname">备注信息</div>
            <div class="recharge_price">

              <input v-model="remark" class="beizhu" type="test">
            </div>
          </div>

          <van-button type="primary" class="paydiv" @click="paytow" color="linear-gradient(to right, #11b98d, #0fa079)">确认支付
          </van-button>
        </div>
      </div>
    </div>
    <keyBoard v-model="value" v-show="show" @getjp="getjp" :show.sync="show"></keyBoard>
  </div>
</template>
  
<script>
import {
  askOrderList,
  checkCash,
  getGarageInfo,
  wxLogin,
  getphone,
  ordercar,
  postcbbpay,
  fixpay,
} from "@/api/check";
import userModel from "../../api/user";
import keyBoard from "@/components/vant-number-plate/vnp-keyboard";
import VnpInput from "@/components/vant-number-plate/vnp-input";
import preventReClick from "@/assets/preventReClick";
import { runInThisContext } from "vm";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入

  components: {
    keyBoard,
    "vnp-input": VnpInput,
  },
  data() {
    return {
      // , "余额充值"
      list: ["代收款", "废品", "其他"], //
      carlist: [],
      gid:this.$route.query.gid,
      phoneisok: false,
      acvred: null,
      phone: "",
      amount: "",
      amount2:'',
      remark:'',
      payType: 2,
      status: null, //工单计收还是预付款
      yfkisok: false,
      acv: 0,
      form: {},
      acvs: "代收款",
      show: false,
      val: ["", "", "", "", "", "", "", ""],
      value: "",
      value2: "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      user: JSON.parse(localStorage.getItem("userInfo")),
      token: "",
    };
  },
  watch: {
    value2(val) {
      if (val.length > 6) {
        this.getcarNo();
      }
    },
  },
  methods: {
    //根据车牌查询未结算信息
    getcarNo() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      this.acvred = null;
      var data = {
        carNo: this.value2,
        gid: this.shangjia.id,
      };
      ordercar(data).then((res) => {
        loading.clear();
        console.log(res);
        if (res.code == 200) {
          this.carlist = res.data;
        }
      });
    },
    //点击车辆结算
    setpay(item, idx) {
      var _this = this;
      this.status = item.status;
      if (item.status>=4) {
        const data = {
          billNumber: item.billNumber,
          oid: item.id,
          payType: _this.payType,
          totalMoney: item.amountReceivable,
          gid: item.gid,
          type: 1,
        };
        fixpay(data).then((e) => {
          if (e.code == 200) {
            if (e.data.success == "true") {
              window.location.href = e.data.qrurl;
            } else {
              this.$toast(e.data.msg);
            }
          }
        });
      } else {
        this.acvred = idx;
        this.amount = item.amountReceivable;
        this.form = item;
        this.yfkisok = true;
      }
    },
    getjp(val) {
      var _this = this;
      console.log(_this.value2);
      _this.value2 = val;
      this.getcarNo();
      // console.log(val);
      // this.addForm.carNo = val;
      // this.pleshow = false;
    },
    freshData() {
      console.log("第一种调用方式");
    },
    setphone() {
      if (!/^1[23456789]\d{9}$/.test(this.phone)) {
        this.$toast("手机号码格式错误");
        return;
      } else if (this.phone == "" || !this.phone) {
        this.$toast("请输入手机号码");
        return;
      }
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      localStorage.setItem("phone", this.phone);
      var data = {
        gid: this.shangjia.id,
        phone: this.phone,
      };
      getphone(data).then((res) => {
        loading.clear();
        if (res.code == 200) {
          this.carlist = res.data;
          // if(res.data.length > 0){
          //   this.value2 = res.data[0].carNo
          // }
          this.phoneisok = false;
        }
      });
    },

    //订单支付
    pay(item) {
      var item = this.form;
      var _this = this;
      var data = {
        carNo: item.carNo,
        gid: item.gid,
        orderId: item.id,
        ordersn: item.billNumber,
        payType: _this.payType,
        price: this.amount,
        type: "99",
      };
      postcbbpay(data).then((res) => {
        console.log(res);
        if (res.data.success == "true") {
          window.location.href = res.data.qrurl;
        } else {
          _this.$toast("调起支付失败");
        }
      });
    },
    //切换

    //其它支付
    paytow() {
      var data = {
        gid: this.gid,
        payType: this.payType,
        price: this.amount2,
        carNo: this.acvs,
        key:'orderOtherPay',
        type:107,
        remark:this.acvs=='其他'?this.remark:''
      };
      postcbbpay(data).then((res) => {
        console.log(res);
        if (res.data.success == "true") {
          window.location.href = res.data.qrurl;
        } else {
          this.$toast("调起支付失败");
        }
      });
    },
    back() {
      parent.WeixinJSBridge.call("closeWindow");
    },
    isWeixin() {
      //判断是否是微信
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.payType = 2;
        return true;
      } else {
        this.payType = 1;
        return false;
      }
    },
    //判断是否为支付宝坏境
    isAlipayClient() {
      if (navigator.userAgent.indexOf("AlipayClient") > -1) {
        this.payType = 1;
        return true;
      }
      this.payType = 2;
      return false;
    },
  },
  created() {
    //判断是否在微信环境
    this.isWeixin();
    
    if (this.$route.query.gid) {
      sessionStorage.setItem("gid", this.$route.query.gid);
    }
    userModel
      .getGarageInfo({ gid: this.$route.query.gid })
      .then((e) => {
        // loading.clear();

        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        if (localStorage.getItem("phone")) {
          this.phone = localStorage.getItem("phone");
          this.setphone();
          this.phoneisok = false;
        } else {
          this.phoneisok = true;
        }
        // return;
        // if (this.isWeixin()) {
        //   var url =
        //     "https://shopping.car-posthouse.cn/ycjpay?gid=" +
        //     this.$route.query.gid;
        //   // 正式
        //   const redirect_uri = encodeURIComponent(url);
        //   window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        // }
      })
      .catch(() => loading.clear());
    // return
    // if (this.$route.query.code == undefined) {
    //   console.log(this.$route.query.code);
    //   var url = this.$route.path + "?gid=" + this.$route.query.gid;
    //   localStorage.setItem("beforeUrl", url);

    //   return;
    // } else {
    //   wxLogin({
    //     code: this.$route.query.code,
    //     gid: sessionStorage.getItem("gid"),
    //   }).then((e) => {
    //     if (e.code == 200) {
    //       localStorage.setItem("Authorization", e.data.authorization);
    //       localStorage.setItem("openId", e.data.openId);
    //       console.log(e);
    //     }
    //   });
    // }
  },
  mounted() {},
  computed: {},
};
</script>
  
<style lang="less" scoped>
body {
  font-size: 14px;
}

.mian {
  // height: 100%;
  padding-top: 46px;
  // background-color: #0d906e;
  background: linear-gradient(110deg, #11b98d, #0fa079);

  .mian_top {
    // padding: 40px 16px 40px 16px;
    padding: 20px 16px;
    display: flex;
    font-size: 18px;
    color: #fff;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;

    .sz {
      font-size: 12px;
      font-weight: normal;
    }

    .imgs {
      width: 50px;
    }
  }

  .mian_b {
    background-color: #fff;
    // height: 300px;
    border-radius: 10px 10px 0 0;
    padding: 28px;

    .middle {
      display: flex;
      font-size: 14px;
      color: #919199;

      .middle_a {
        margin-right: 39px;
        text-align: center;

        .heng {
          width: 15px;
          display: block;
          margin: auto;
          margin-top: 5px;
        }
      }

      .acv {
        font-size: 16px;
        color: #0fa079;
      }
    }

    .carname {
      font-size: 16px;
      color: #242433;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    .car {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      .carnumber {
        width: calc((100% - 56px) / 8);
        height: 40px;
        line-height: 40px;
        border-radius: 2px;
        background: #f3f3f3;
        margin-right: 8px;
      }
      .carnumber:last-child {
        margin-right: 0;
      }
      .new-energy {
        margin-right: 0;
      }
    }

    .bind-phone {
      margin-bottom: 15px;

      .tips {
        color: #f55459;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 15px;
      }

      .input-phone {
        display: flex;
        font-size: 16px;

        .van-button {
        }

        input {
          flex: 1;
          border: 1px solid #bbb;
          border-radius: 5px;
          margin-right: 10px;
          padding: 2px 10px;
        }

        .van-button {
          font-size: 15px;
        }
      }
      .acvred {
        border: 2px solid #f03037;
        border-radius: 4px;
      }
      .fang {
        display: -webkit-box;
        display: -webkit-flex;
        box-sizing: border-box;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 10px;
        background: #fff6f7;
        margin-bottom: 15px;
        .content {
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          flex: 1;
          .title {
            font-size: 19px;
            font-weight: 600;
          }
          .offer_price {
            font-weight: 600;
            font-size: 15px;
            color: #f03037;
          }
        }
        .red-btn {
          padding: 5px 10px;
          opacity: 1;
          background: #f03037;
          border-radius: 4px;
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .list {
      display: flex;
      box-sizing: border-box;
      font-size: 13px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #242433;

      div {
        padding: 0px 12px;
        margin-bottom: 10px;
        border-radius: 24px;
        font-size: 12px;
        display: flex;
      }

      .acvs {
        background: linear-gradient(289deg, #0fa079, #11b98d);
        color: #fff;
      }
    }

    .recharge_price {
      display: flex;
      margin-bottom: 5px;
      border-bottom: 1px solid #eee;
      font-size: 16px;

      .unit {
        margin-right: 5px;
      }

      .jiner {
        border: none;
        flex: 1;
        font-size: 35px;
        font-weight: 500;
      }

      .beizhu {
        border: none;
        flex: 1;
        font-size: 15px;
        // font-weight: 500;
      }
    }

    .paydiv {
      width: 100%;
      font-size: 16px;
    }
  }
}
</style>

  
  